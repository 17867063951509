import Authorities from "./authorities.service";

class Authority {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Authority.build(payload),
    });
  }

  static build({
    id = "",
    name = "",
    slug = "",
    level = "",
    is_active = "",
    permissions = {},
    created_at = "",
    updated_at = "",
  }) {
    return {
      id,
      name,
      slug,
      level,
      is_active,
      permissions,
      created_at,
      updated_at,
    };
  }

  static newData(payload) {
    return new Authority(payload);
  }

  static list(params) {
    return new Promise((resolve, reject) => {
      Authorities.list(params).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static get(id) {
    return new Promise((resolve, reject) => {
      Authorities
          .get(id)
          .then(
              success => {
                const authority = success;
                authority.data = this.newData(authority.data);

                resolve(authority);
              },

              error => {
                reject(error);
              }
          );
    });
  }

  static getHighLevel(params) {
    return new Promise((resolve, reject) => {
      Authorities.getHighLevel(params).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static getNameHighLevel() {
    return new Promise((resolve, reject) => {
      Authorities.getNameHighLevel().then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static getBySlug(slug) {
    return new Promise((resolve, reject) => {
      Authorities.getBySlug(slug).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.original.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      Authorities.create(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static edit(payload = {}) {
    return new Promise((resolve, reject) => {
      Authorities.edit(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      Authorities
      .delete(payload)
      .then(success => {
        resolve(success);
      }, error => {
        reject(error);
      });
    });
  }
}

export default Authority;
