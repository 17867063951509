<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar
        :toolbarName="dynamicToolbarName"
        :openDetails="openDetails"
    />

    <form-dialog
        :dialog="formDialog"
        :formName="dynamicFormName"
    >
      <v-form ref="formTechnician">
            <v-toolbar flat v-if="canToggleUser">
              <v-switch
                v-model="payload.is_active"
                :label="getStatus(payload.is_active)"
                color="red"
                @change="toggleUser(payload.id)"
                :value="payload.is_active"
                hide-details
              ></v-switch>
              <v-spacer></v-spacer>
            </v-toolbar>
        <v-row>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Nome"/>
            <simple-text-field v-model="payload.name"
                              required
                              placeholder="Nome"
            />
          </v-col>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="E-mail"/>
            <simple-text-field v-model="payload.email"
                              required
                              email
                              placeholder="E-mail"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <custom-label label="Telefone"/>
            <simple-text-field v-model="payload.phone"
                              mask="phone"
                              placeholder="Telefone"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6" v-if="!openDetails">
            <custom-label label="Status"/>
            <simple-select
              v-model="payload.is_active"
              :items="statusType"
              itemText="text"
              itemValue="value"
              class="mr-2"
              placeholder="Status"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6" v-if="openDetails">
            <span class="font-weight-bold">Senha</span> <br/>

            <primary-button
                label="Nova Senha"
                style="margin-top: 0.3rem;"
                type="button"
                @callAction="showConfirmResetPasswordDialog = true"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select
          v-model="isActiveSearch"
          :items="statusType"
          @input="filterByStatus()"
          itemText="text"
          itemValue="value"
          class="mr-2"
          placeholder="Status"
          height="0"
      />
    </search-bar>

    <row-details v-if="openDetails">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <col-details column="4" name="Nome" :item="payload.name"/>
        <col-details column="2" name="Status" :item="getStatus(payload.is_active)"/>
        <col-details column="4" name="E-mail" :item="payload.email"/>
        <col-details column="2" name="Telefone" :item="payload.phone"/>
      </v-row>
    </row-details>

    <data-table
        :headers="headers"
        :fetching="fetching"
        :data="data"
        :search="search.text"
        :objectName="objectName"
        @loadAgain="loadAgain"
        v-else
    >
      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
            class="ml-4"
            small
            @click="view(item)"
        >
          mdi-information-outline
        </v-icon>

        <v-icon v-if="authoritySlug === 'super-admin' || permissions.access_technicians"
          class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-reset-password-dialog
      :payload="this.payload"
      :dialog.sync="showConfirmResetPasswordDialog"
      @closeDialog="closeConfirmResetPasswordDialog"
    />

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import Users from "@/domain/users/users";
import Authorities from "@/domain/authorities/authorities";
import ConfirmResetPasswordDialog from "@/components/dialogs/login/ConfirmResetPasswordDialog";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
import {getItem} from '@/services/local-storage.service';

export default {
  name: "Technicians",
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    RowDetails,
    ColDetails,
    CustomLabel,
    SimpleTextField,
    SearchBar,
    SimpleSelect,
    ConfirmResetPasswordDialog,
    ConfirmDestroyDialog,
    PrimaryButton
  },
  data() {
    return {
      objectName: "técnicos",
      toolbarName: "Lista de Técnicos",
      formName: "Adicionar Técnico",

      fetching: true,
      search: {},
      isActiveSearch: "",
      data: [],
      authorities: [],

      statusType:
          [
            {text: "Ativo", value: true},
            {text: "Inativo", value: false}
          ],

      userService: null,
      authorityService: null,

      payload: null,
      selected: null,
      canToggleUser: false,

      slug: "technician",
      authority_id: 0,

      openDetails: false,
      formDialog: false,
      showConfirmResetPasswordDialog: false,
      showConfirmDestroyDialog: false,

      authoritySlug: '',
      permissions: '',
    };
  },
  methods: {
    getUsers() {
      this.fetching = true;
      this.data     = [];

      this.userService.list().then((result) => {
        result.data.map((itm) => {
          if (itm.authority.slug === this.slug) {
            this.data.push(itm);
          }
        });
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });

      this.fetching = false;
    },
    getAuthority() {
      let loader = this.$loading.show();

      this.authorityService.getBySlug(this.slug).then((result) => {
        result.data.map((itm) => {
          this.authority_id = itm.id;
        });
      });
      loader.hide();
    },
    getStatus(status) {
      if (status === true) return "Ativo";

      return "Inativo";
    },
    toggleUser(id) {
      let loader = this.$loading.show();

      Users.toggle(id)
          .then(() => {
            loader.hide();
            this.$toasted.show("Status alterado com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
    },
    initialize() {
      this.userService = Users;
      this.authorityService = Authorities;
      this.payload = this.userService.newData();

      this.authoritySlug = getItem('authoritySlug');
      this.permissions   = JSON.parse(getItem('permissions'));

      this.getUsers();
      this.getAuthority();
    },
    view(item) {
      this.payload = Object.assign({}, item);
      this.selected = Object.assign({}, item);
      this.openDetails = true;
    },
    closeDetails() {
      this.payload = {};
      this.selected = {};
      this.openDetails = false;
    },
    create() {
      this.payload = {};
      this.payload.is_active = true;
      this.selected = {};
      this.canToggleUser = false;
      this.formDialog = true;
    },
    edit() {
      this.canToggleUser = true;
      this.formDialog = true;
    },
    deleteItem(item) {
      this.payload = Object.assign({}, item);
      this.showConfirmDestroyDialog = true;
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeFormDialog() {
      this.selected = {};
      this.formDialog = false;
    },
    closeView() {
      this.openDetails = false;
      this.initialize();
    },
    save() {
      if (!this.$refs.formTechnician.validate()) {
        return;
      }

      let loader = this.$loading.show();

      this.payload.authority_id = this.authority_id;
      this.payload.is_active = this.payload.is_active === null
        ? false : this.payload.is_active;

      if (!this.openDetails) {
        this.userService
            .create(this.payload)
            .then(() => {
              loader.hide();
              this.closeFormDialog();
              this.initialize();
              this.$toasted.show("Usuário cadastrado com sucesso.", {
                type: "success",
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      } else {
        this.userService
            .edit(this.payload)
            .then(() => {
              loader.hide();
              this.closeFormDialog();
              this.$toasted.show("Usuário atualizado com sucesso.", {
                type: "success",
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      }
    },
    async destroy() {
      const loader = this.$loading.show();

      await this.userService.delete(this.payload).then(() => {
        loader.hide();
        this.getUsers();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Técnico removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Técnico não foi removido.", {
          type: 'error'
        });
      });
    },
    filterByStatus() {
      this.search.is_active = this.isActiveSearch;
    },
    clearFilters() {
      this.search = {};
      this.isActiveSearch = "";
    },
    closeConfirmResetPasswordDialog() {
      this.showConfirmResetPasswordDialog = false;
    },
    loadAgain() {
      this.initialize();
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: this.canBeSortable,
        },
        {text: "Nome", value: "name", sortable: this.canBeSortable},
        {
          text: "Status", value: "is_active", sortable: this.canBeSortable,
          filter: value => {
            if (this.isActiveSearch === "") return true
            return value === this.search.is_active;
          }
        },
        {text: "Detalhes", value: "actions", sortable: false},
      ]
    },
    canBeSortable() {
      return !this.fetching;
    },
    dynamicToolbarName() {
      if (this.openDetails) {
        return this.toolbarName + " / Detalhes";
      }

      return this.toolbarName;
    },
    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Técnico";
      }

      return this.formName;
    },
  },
  beforeMount() {
    this.initialize();
  }
};
</script>
