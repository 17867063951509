import http from '@/services/http.service';

const apiUrl = '/authorities';

class AuthoritiesService {
    static list(params) {
        return http.get(`${apiUrl}`, { params });
    }
    static create(payload) {
        return http.post(`${apiUrl}`, payload);
    }
    static edit(payload) {
        return http.put(`${apiUrl}/${payload.id}`, payload);
    }
    static delete(payload) {
        return http.delete(`${apiUrl}/${payload.id}`);
    }
    static get(id) {
        return http.get(`${apiUrl}/${id}`);
    }
    static getHighLevel() {
        return http.get(`${apiUrl}/get-high-level`);
    }
    static getBySlug(slug) {
        return http.get(`${apiUrl}/authority/${slug}`);
    }
    static getNameHighLevel() {
        return http.get(`${apiUrl}/get-name-high-level`);
    }
}

export default AuthoritiesService;
