import Users from "./users.service";

class User {
    constructor(payload = {}) {
        Object.assign(this, {
            ...User.build(payload),
        });
    }

    static build(
        {
            id = '',
            name = '',
            email = '',
            phone = '',
            authority_id = '',
            state_id = '',
            cpf = '',
            responsible_success_advisor_id = '',
            complementary_success_advisor_id = '',
            authority = {},
            state = {},
            producer = {},
            irrigation = '',
            farm_size = '',
            area_type = '',
            is_sower = '',
            is_active = '',
            created_at = '',
            updated_at = '',
        }
    ) {
        return {
            id,
            name,
            email,
            phone,
            authority_id,
            state_id,
            cpf,
            responsible_success_advisor_id,
            complementary_success_advisor_id,
            authority,
            state,
            producer,
            irrigation,
            farm_size,
            area_type,
            is_sower,
            is_active,
            created_at,
            updated_at,
        }
    }

    static newData(payload) {
        return new User(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            Users
                .list(params)
                .then((success) => {
                    const {data} = success;
                    const list = Object.assign({}, {...data});
                    list.data = data.data.map(item => this.newData(item));
                    resolve(list);
                }, (error) => {
                    reject(error);
                })
        });
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
          Users
            .create(payload)
            .then((success) => {
              resolve(success);
            }, (error) => {
              reject(error);
            });
        });
      }

      static edit(payload = {}) {
        return new Promise((resolve, reject) => {
          Users
            .edit(payload)
            .then((success) => {
              resolve(success);
            }, (error) => {
              reject(error);
            });
        });
      }

      static toggle(id) {
        return new Promise((resolve, reject) => {
          Users
            .toggle(id)
            .then((success) => {
              resolve(success);
            }, (error) => {
              reject(error);
            });
        });
      }

    static getSuccessAdvisors() {
      return new Promise((resolve, reject) => {
        Users
          .getSuccessAdvisors()
          .then((success) => {
            const {data} = success;
            const list = Object.assign({}, {...data});
            list.data = data.data.map(item => this.newData(item));
            resolve(list);
          }, (error) => {
            reject(error);
          })
      });
    }

    static delete(payload) {
      return new Promise((resolve, reject) => {
        Users
        .delete(payload)
        .then(success => {
          resolve(success);
        }, error => {
          reject(error);
        });
      });
    }

    static getUsersByAuthority(authorityId) {
      return new Promise((resolve, reject) => {
        Users
          .getUsersByAuthority(authorityId)
          .then((success) => {
            const {data} = success;
            const list = Object.assign({}, {...data});
            list.data = data.data.map(item => this.newData(item));
            resolve(list);
          }, (error) => {
            reject(error);
          })
      });
    }

    static updateUsersAuthority(newAuthority, oldAuthority) {
      return new Promise((resolve, reject) => {
        Users
          .updateUsersAuthority(newAuthority, oldAuthority)
          .then((success) => {
            resolve(success);
          }, (error) => {
            reject(error);
          });
      });
    }
}

export default User;
