import http from '@/services/http.service';

const apiUrl = '/users';

class UsersService {
    static list(params) {
        return http.get(`${apiUrl}`, { params });
    }
    static create(payload) {
        return http.post(`${apiUrl}`, payload);
    }
    static edit(payload) {
        return http.put(`${apiUrl}/${payload.id}`, payload);
    }
    static get(id) {
        return http.get(`${apiUrl}/${id}`);
    }
    static toggle(id) {
        return http.get(`${apiUrl}/toggle-status/${id}`);
    }
    static getSuccessAdvisors() {
        return http.get('as');
    }
    static delete(payload) {
        return http.delete(`${apiUrl}/${payload.id}`);
    }
    static getUsersByAuthority(authorityId) {
        return http.get(`${apiUrl}/authorities/${authorityId}`);
    }
    static updateUsersAuthority(newAuthority, oldAuthority) {
        return http.put(`${apiUrl}/authorities/new/${newAuthority}/old/${oldAuthority}`);
    }
}

export default UsersService;
